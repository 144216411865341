<!-- View de ADPS -->

<template>
  <div class="apd_M_view" v-if="schema"
       style="align-self:start">

    <v-dialog v-model="dialog" content-class="modal" persistent> 
        <template v-slot:activator="{on}">
        
          <!-- Botón para abrir la ventana -->
          <v-btn 
            v-on="accionRow.id>0? on : ''"
            v-on:click="accionRow.id>0? btra_Mto({ accion:0 }) : ''"
            v-bind="$cfg.btra_cfg.standard"
            title="Ver APD">
              <v-icon>{{'mdi-eye'}}</v-icon>
          </v-btn>   
        </template>

        <div class="contenedor">
          <v-sheet :elevation="4">        
            <!-- Cabecera -->
            <div class="cabecera">
              <base_Header
                v-if="Entorno.header.header"       
                :Entorno="Entorno.header"
                @onEvent="dialog=false">
              </base_Header>                  
            </div>

            <!-- Controles del Mto -->
            <div class="conflex contenedor">              
              <div class="columna" style="width:500px;">             
                <div class="cab">DATOS APD</div>
                <v-sheet v-bind="$cfg.styles.marco">
                  <div class="columna">                   
                    <div class="conflex">
                      <v-text-field
                        style="width:80%"
                        v-bind="$input"
                        v-model="schema.ctrls.name.value"
                        :label="schema.ctrls.name.label"
                        :disabled="true">            
                      </v-text-field>                      
                      <v-text-field
                        style="width:20%"
                        v-bind="$input"
                        v-model="schema.ctrls.codigo.value"
                        :label="schema.ctrls.codigo.label"
                        :disabled="true">              
                      </v-text-field>
                    </div>

                     <div class="conflex">
                      <v-text-field    
                         style="width:60%"                  
                        v-bind="$input"
                        v-model="schema.ctrls.name_idjefe.value"
                        :label="schema.ctrls.name_idjefe.label"
                        :disabled="true">              
                      </v-text-field>
                      <v-text-field          
                         style="width:40%"          
                        v-bind="$input"
                        v-model="schema.ctrls.name_agencia.value"
                        :label="schema.ctrls.name_agencia.label"
                        :disabled="true">              
                      </v-text-field>
                    </div>

                    <div class="conflex">
                      <v-text-field                  
                        v-bind="$input"
                        v-model="schema.ctrls.tlf1.value"
                        :label="schema.ctrls.tlf1.label"
                        :disabled="true">              
                      </v-text-field>

                      <v-text-field                  
                        v-bind="$input"
                        v-model="schema.ctrls.tlf2.value"
                        :label="schema.ctrls.tlf2.label"
                        :disabled="true">              
                      </v-text-field>

                    </div>
                    <div class="conflex">
                      <v-text-field                  
                        v-bind="$input"
                        v-model="schema.ctrls.mov1.value"
                        :label="schema.ctrls.mov1.label"
                        :disabled="true">              
                      </v-text-field>                                    
                      <v-text-field                  
                        v-bind="$input"
                        v-model="schema.ctrls.mov2.value"
                        :label="schema.ctrls.mov2.label"
                        :disabled="true">              
                      </v-text-field>
                    </div>
                    <div class="conflex">
                      <v-text-field                  
                        v-bind="$input"
                        v-model="schema.ctrls.fax1.value"
                        :label="schema.ctrls.fax1.label"
                        :disabled="true">              
                      </v-text-field>

                      <v-text-field                  
                        v-bind="$input"
                        v-model="schema.ctrls.fax2.value"
                        :label="schema.ctrls.fax2.label"
                        :disabled="true">              
                      </v-text-field>
                    </div>

                    <v-text-field                  
                      v-bind="$input"
                      v-model="schema.ctrls.email1.value"
                      :label="schema.ctrls.email1.label"
                      :disabled="true">              
                    </v-text-field>
                  
                    <v-text-field                  
                      v-bind="$input"
                      v-model="schema.ctrls.email2.value"
                      :label="schema.ctrls.email2.label"
                      :disabled="true">              
                    </v-text-field>
                    
                    <!-- <ctrlobs3
                      v-show="rol=='jz'"
                      :schemaComponente="schema.ctrls.obs"
                      :schema="schema"   
                      :record="accionRow"
                      :edicion="false">
                    </ctrlobs3> -->
                  </div>
                </v-sheet> 
              </div>

              <div v-if="app==1||rol=='jz'" class="columna" style="width:380px;padding-left:10px">  
                <v-btn
                  v-bind="$cfg.btra_cfg.standard"
                  title="Envio SMS a APD"
                  @click="envio_sms">
                    <v-icon>{{'mdi-send-outline'}}</v-icon>
                </v-btn> 

                <div class="cab" style="margin-top:10px">SMS</div>
                <v-sheet v-bind="$cfg.styles.marco">
                  <v-textarea
                    v-bind="$textarea"
                    v-model="schema.ctrls.txtsms.value"
                    :label="schema.ctrls.txtsms.label"
                    rows="6"
                    no-resize
                    :disabled="false">              
                  </v-textarea>   
                </v-sheet>
              </div>
            </div>
      
          </v-sheet>
        </div>
    </v-dialog>    
  </div>
</template>


<script>
 
  import { mixinMto } from "@/mixins/mixinMto.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  /* const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp"); */

  export default {
    mixins: [mixinMto],
    components: { base_Header },

    props: {      
      padre: { type:String, default: '' }, 
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: ()=> {}},
      auxRecord: { type:Object, default: ()=> {}},
      apd: { type:Number, default: 0},
      disparo: { type:Boolean, default:false },
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: null,
        api:'apd_M_view',
        stName:'stMapd_view',
       
        dialog:false,       
      };
    },

    
    
    // secuencia del componente:
    //  mixinMto:
    //        1 create()
    //             2   ini_data()=>funes_M  (Inicializo datos reactivos y lo necesario para cargar schema(API))
    //             3   this.schema = await ... (cargo schema });
    //             4   ini_SchemaCtrls()=>funes_M ( ya tengo el schema y sigo la secuencia con ya todo lo necesario)
    //                                 this.component_base="base_M"; (lanzo el componente base hijo)
    //                                                                 (elimino if schema del template, no hace falta)
    //             5 await this.acciones_Mto({ accion: this.accion });  
    //             6 se pinta el componente  

  
    methods: {

      ini_data(){
        console.log("DEV " + this.api + " ********************** (ini_data)");
        
        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema; 

        // configuro Entorno 
        this.Entorno.header.titulo = "APDS"; 
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo.standardM));                     
      },


      // override fn que se ejecuta al dispararse el mto
      ini_component() {},


      // en gestión, añadimos un mensaje al texto de sms diferenciando si es Apd1 o 2
      record_cargado() {       
        if (this.app!=1) return;
        if (this.apd==1) {
          this.schema.ctrls.txtsms.value= (this.auxRecord.callemp == '83'? 'MFF' : 'OTC') + ' Exp: ' + this.auxRecord.control + ' '
            + this.auxRecord.fallename + ' (FAMILIAR) ' + this.auxRecord.fallecont1 + ' Tlf: ' + this.auxRecord.fallecont1tlf1 
            + ' En: ' + this.auxRecord.falleen + ' ' + this.auxRecord.name_falleenloc;
        }

        if (this.apd==2) {
          this.schema.ctrls.txtsms.value= (this.auxRecord.callemp == '83'? 'MFF' : 'OTC') + ' Exp: ' + this.auxRecord.control + ' '
            + this.auxRecord.fallename + ' (FAMILIAR) ' + this.auxRecord.fallecont2 + ' Tlf: ' + this.auxRecord.fallecont2tlf1 
            + ' En: ' + this.auxRecord.falledest + ' ' + this.auxRecord.name_falledestloc;
        }
         
      },


      //
      async envio_sms() {
        if (this.schema.ctrls.txtsms.value== "") return;
        
        let apiResult= await this.$store.dispatch("envio_SMS", { mov:"A" + this.schema.ctrls.id.value, txt:this.schema.ctrls.txtsms.value });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Mensaje enviado correctamente', 'success', 1800);
      }


    }
  };
</script>
